import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {TokenService} from './token.service';
import {Route, MESSAGES} from '../../shared/constants/index';
import {Router} from '@angular/router';
import { DATA_ENTRY_PAGE } from '../constants/uiConstants';
import { LoginService } from '../api';

@Injectable()
export class ErrorService {

  constructor(private notificationService: NotificationService,
              private tokenService: TokenService,
              private router: Router,
            private loginService:LoginService) {
  }
  // TODO: NL - this is heavily used, but really doesn't need to be it's own service,
  // consider moving into new abstract http service class when implemented, since http services should
  // be the only ones referencing this code

  public handleErrorStatusCode(error): any {
   
    if(error.error != null ){
      if(error.error.status == 502){
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_INVALID_CREDENTIAL);
      return;
      }
      if (error.error.status == 404) {
        console.log(error); // Log the error to inspect it
        
        // If the notificationService should display an error, ensure the message is correct
        if (MESSAGES.ERROR.HEADER.ERROR_MESSAGE && MESSAGES.ERROR.USER_EXPIRED) {
            this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_EXPIRED);
        } else {
            console.error("Error messages are not defined properly");
        }
    
        // Try to log the message first to see if it's firing
        setTimeout(() => {
            console.log("Hello World"); 
        }, 3000);
    
        // Perform logout and redirect after 3 seconds (to ensure timeout runs first)
        setTimeout(() => {
            this.loginService.logout();
            window.location.href = '/login'; // Redirect to login 
        }, 3000); // Same delay, so logout happens after timeout
    
        // Optional: If you want to navigate without using window.location
        // this.router.navigate(['/login']);
    }
    
    }

    else if (error.status === 403 || (error.error != null && error.error.status === 403)) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCESS_DENIED);
    } //else if (error.status === 404) {
     // this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_INVALID_CREDENTIAL);
   // }
    else if (error.status === 406) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_NOT_EXIST);
    } else if (error.status === 409) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.CONFLICTING_RECORD);
    } else if (error.status === 412) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_NOT_EXIST);
    } else if (error.status === 500) {
     // this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.SERVER_ERROR);
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.INTERNAL_SERVER_ERROR);
      
    } else if (error.status === 501) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.FORGOT_PASSWORD_INITIATED);
    } else if (error.status === 502) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.WRONG_USERNAME_PASSWORD);
    } else if (error.status === 503) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACTIVATE_ACCOUNT);
    } else if (error.status === 504) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCOUNT_LOCKED);
    } else if (error.status === 505) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCOUNT_EXPIRED);
    } else {
      //this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ERROR_CONTACT_ADMIN);
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.AN_ERROR_OCCURRED);
     
    }

  }

}
