import { Component, Input } from '@angular/core';
import {PolicyType} from "../../../shared/model/policy-type";
import {Policy, PolicyQuestionAnswer} from "../../../shared/model/policy";
import {InputType, Question, QuestionType} from "../../../shared/model/question";
import {sortByQuestionCode} from "../../../util/alphaNumericSort";
import {CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT as UI_CONSTANTS} from "../../../shared/constants/uiConstants";
import { PolicyFilterService } from '../../../shared/api/policy.filter.service';

interface PolicyResponse {
    question: Question;
    answer: PolicyQuestionAnswer;
  
}

@Component({
  templateUrl: 'printed-policy.component.html',
  selector: 'app-printed-policy'
})
export class PrintedPolicyComponent {
  @Input() public policyTypes?: PolicyType[];
  @Input() public activePolicy?: Policy;
  @Input() public policyIndex?: string;
  //public static readonly DOM_ID: string = 'printed_policy_component';
  //public readonly _id: string = PrintedPolicyComponent.DOM_ID;
  public static  DOM_ID: string = 'printed_policy_component';
  public  _id: string = PrintedPolicyComponent.DOM_ID;
  

  public UI = UI_CONSTANTS;

  public policyType?: PolicyType;
  public customQuestions:any[]=[];

  private answersMap: Map<number, PolicyQuestionAnswer> = new Map<number, PolicyQuestionAnswer>();
    // maps question.id to QuestionResponse objects for each selected answer
  public responses: Map<number, PolicyResponse[]> = new Map<number, PolicyResponse[]>();

  constructor(private policyFilterService:PolicyFilterService){

        
        
    }

  public ngOnInit(): void {
    
    if(this.policyIndex != undefined){
        //this._id=this.policyIndex.toString();
        this._id="policy"+this.policyIndex;
    }
      this.loadPolicyType();  
      console.assert(undefined != this.policyTypes, 'PrintedFormComponent#ngOnInit - policyTypes is undefined');
      const policyType = this.policyTypes.find(p => this.activePolicy.policyTypeId === p.id);

      if (policyType != null) {
         this.policyType = policyType;
      } else {
          console.assert(false, 'AuditSetupPageComponent#ngOnInit - failed to find policyType matching activePolicy');
      }
      

    if (this.activePolicy && this.activePolicy.answers) {
      for (let answer of this.activePolicy.answers) {
        this.answersMap.set(answer.questionAnswerId, answer);
      }
    }
    

      /*if (this.policyType) {
          for (let question of this.policyType.questions) {
              if(question.answers !== null)
              for (let questionAnswer of question.answers) {
                  const answer = this.answersMap.get(questionAnswer.id);
                  if (answer) {
                      const questionResponses = this.responses.get(question.id) || [];
                      const response: PolicyResponse = {
                          question,
                          answer
                      };
                      questionResponses.push(response);
                      this.responses.set(question.id, questionResponses);
                  }
              }
          }
      }*/
      if (this.policyType) {
          for (let question of this.policyType.questions) {
              if(question.answers !== null)
              for (let questionAnswer of question.answers) {
                  const answer = this.answersMap.get(questionAnswer.id);
                  if (answer) {
                      const questionResponses = this.responses.get(question.id) || [];
                      const response: PolicyResponse = {
                          question,
                          answer                          
                      };
                      questionResponses.push(response);
                      this.responses.set(question.id, questionResponses);
                  }
              }
          }
      }
  }

    public get enabledQuestions(): Question[] {
        if (this.policyType != null) {
            const allQuestionsForPolicyType: Question[] = this.policyType.questions;
            let out: Question[] = [];

            allQuestionsForPolicyType.forEach(q => {
                if (this.shouldDisplayQuestion(q)) {
                    out.push(q);
                }
            });

            return sortByQuestionCode(out);
        }

        return [];
    }

    public get answersSummary(): PolicyResponse[][] {
        let out: PolicyResponse[][] = [];
        this.enabledQuestions.forEach((question: Question) => {
            let questionResponses = this.responses.get(question.id);
            if (questionResponses) {
                //INC0391273 - Ordering the answers for Audit Scope
                if(question.id === 327)
                {
                        questionResponses[0].question.answers = questionResponses[0].question.answers.sort((a, b) => a.code.localeCompare(b.code));
                        questionResponses.sort((a, b) => a.answer.answerValue.localeCompare(b.answer.answerValue));
                        out.push(questionResponses);
                }
                else
                    out.push(questionResponses);
            }
        });
        return out;
    }

  public shouldDisplayQuestion(question: Question): boolean {
      if (undefined == this.activePolicy) {
          console.assert(false, 'AuditSetupPageComponent#shouldDisplayQuestion - activePolicy was undefined');
          return false;
      }
      if (QuestionType.AUDIT == question.type) {
          return false;
      }
      if (undefined == question.conditions || question.conditions.length === 0) {
          return true;
      }

      let out: boolean = false;
      question.conditions.forEach(c => {
          if (out) {
              // conditions are a logical OR, once we find one that is satisfied, no need to check the rest.
              return;
          }
          const match = this.answersMap.get(c.conditionAnswerId);

          if (undefined != match) {
              out = true;
          }
      });

      return out;
  }

  public labelForAnswer(question: Question, answer: PolicyQuestionAnswer): string {
      if (question.inputType === InputType.TEXT) {
          return question.title + ' ' + answer.answerValue;
      }
      const questionAnswer = question.answers.find(qa => qa.id == answer.questionAnswerId);
      if (questionAnswer) {
          if(questionAnswer.type=="FREE_FORM")
          return questionAnswer.label + ' ' + answer.answerValue;
          return questionAnswer.label;
      }
      console.assert(false, 'PrintedPolicyComponent#labelForAnswer - failed to find matching questionAnswer record for policy answer.');
      return answer.answerValue;
  }

  private loadPolicyType(): Promise<void> {
    return new Promise(resolve => {
        //this.policyTypeService.getAllEntities().subscribe(
        this.policyFilterService.getPolicy(this.activePolicy.facilityId).subscribe(
        //this.policyFilterService.getPolicyById(this.activePolicy.id).subscribe(
          policyTypes => {
                this.policyTypes = policyTypes;      
                
                if(this.activePolicy!== null && this.activePolicy !== undefined)
                this.policyType = this.policyTypes.find(t => t.id == this.activePolicy.policyTypeId);
               
                resolve();
            },
            err => {
                //this.errorService.handleErrorStatusCode(err);
            }
        );
    });
  }
}
