import {Component, ViewChild, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {NgControl, NgForm} from '@angular/forms';
import {ErrorService} from '../../shared/service/error-service';
import {NotificationService} from '../../shared/service/notification.service';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {ADD_AND_EDIT_USER_ROLE_MODAL_DIALOG as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {UserRole} from '../../shared/model/role';
import {AppBlurService} from '../../shared/service/app-blur.service';
import {Facility} from '../../shared/model/facility';
import {RoleService} from '../../shared/api';
import { Utils } from '../../shared/service/utils';
import { IRoleListingRecord } from '../../shared/interfaces/irolelisringrecord';
import { User } from '../../shared/model/user';
import { UserService } from '../../shared/api';

export enum AddAndEditUserRoleModalDialogMode {
    Add,
    Edit,
}

export interface IAddAndEditUserRoleModalDialog {
    mode: AddAndEditUserRoleModalDialogMode;
    canDelete?: boolean;
    initialFacility: Facility;
    selectedFacility?: Facility;
    selectedRole: UserRole;
    permittedFacilityList: Facility[];
    permittedRoleList: UserRole[];
    registeredRoles: IRoleListingRecord[];
}

export interface IAddAndEditUserRoleModalDialogResult {
    selectedFacility?: Facility;
    selectedRole: UserRole;
    shouldDelete?: boolean;
}

@Component({
    selector: 'app-create-and-edit-user-role-modal-dialog',
    templateUrl: './add-and-edit-user-role-modal-dialog.component.html',
    styleUrls: ['./add-and-edit-user-role-modal-dialog.component.scss'],
})
export class AddAndEditUserRoleModalDialogComponent
    extends SimpleModalComponent<IAddAndEditUserRoleModalDialog, IAddAndEditUserRoleModalDialogResult>
    implements IAddAndEditUserRoleModalDialog, OnInit, AfterViewInit, OnDestroy {
    public registeredRoles: IRoleListingRecord[];
    public mode: AddAndEditUserRoleModalDialogMode = AddAndEditUserRoleModalDialogMode.Add;
    public initialFacility: Facility;
    public selectedFacility?: Facility;
    public selectedRole: UserRole;
    public permittedFacilityList: Facility[];
    public permittedRoleList: UserRole[];
    public canDelete?: boolean;
    public UI = UI_CONSTANTS;
    private initialRole: UserRole;

    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('facilitySelect') public facilitySelect: NgControl;
    @ViewChild('roleSelect') public roleSelect: NgControl;

    constructor(
        private errorService: ErrorService, 
        private notificationService: NotificationService,
        private appBlurService: AppBlurService,
        private roleService: RoleService,
        private utilService: Utils,
        private userService:UserService
    ) {
        super();
    }

    /**
     * Use this to filter roles+facility that already exist.
     * At this point all data is available. 
     * TODO: Filter to a predefined role+facility to make them show in UI.
     */
    ngOnInit() {
        if (this.mode === AddAndEditUserRoleModalDialogMode.Add) {
            this.selectedFacility = null;
            this.selectedRole = null;
        }
        this.initialRole = this.selectedRole;
        console.log(this.selectedRole);
    }

    /**
     * If we have a role selected, check if the role requires a facility.
     */
    get shouldRequireFacility(): boolean {
        if (this.selectedRole !== null) {
            if (this.utilService.doesRoleRequireFacility(this.selectedRole)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Allow only facilities in the dropdown that are permitted:
     * are not used in combination with current role or
     * current role matches initial role and facility matches initial role
     * 
     * when role does not require facility do not filter as we are not using it.
     */
    public get availableFacilities(): Facility[] {
             if (this.selectedRole != null) {
            if (this.utilService.doesRoleRequireFacility(this.selectedRole)) {
                return this.permittedFacilityList.filter(facility => {
                    const linksWithFacility = this.registeredRoles.filter(_ => _.facility != null);
                    if(!facility.active)
                        return false;
                    if(this.userService.isUserAdminAtFacilityToEditFaclility(facility.id) ==false){
                        //this.notificationService.warn('','You are not the Admin for selected facility. Please select any other facility.');
                        return false;
                    }
                        
                    if (!linksWithFacility.find(_ => _.facility.id === facility.id)) {
                        return true;
                    }
                    if (linksWithFacility.find(
                            _ => _.facility.id === facility.id && _.role.id !== this.selectedRole.id)) {
                        return true;
                    }
                    if (this.initialFacility != null) {
                        if (this.initialFacility.id === facility.id && this.selectedRole.id ===  this.initialRole.id) {
                            return true;
                        }
                    }
                    return false;
                });
            } else {
                return this.permittedFacilityList;
            }
        } else {
            return this.permittedFacilityList;
        }
    }

    public ngAfterViewInit() {
        this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    public onSelectedRoleChanged(newRole: UserRole) {
        if (this.utilService.doesRoleRequireFacility(newRole) === false) {
            this.selectedFacility = null;
        }
        this.selectedRole = newRole;
        console.log(JSON.stringify(this.selectedRole));

        if (this.selectedFacility != null && -1 == this.availableFacilities.indexOf(this.selectedFacility)) {
            this.selectedFacility = null;
        }
    }

    public onDeletePressed(): void {
        console.assert(
            this.mode == AddAndEditUserRoleModalDialogMode.Edit,
            'AddAndEditUserRoleModalDialogComponent#onDeletePressed - this.mode is not Edit'
        );
        this.populateResult(true);
        this.close();
    }

    public onSubmitPressed(): void {
        console.assert(
            this.formContainer != null,
            'UserAddComponent#AddAndEditUserRoleModalDialogComponent - this.formContainer was null'
        );

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }

        this.populateResult();
        this.close();
    }

    public get labelForSubmitButton(): string {
        switch (this.mode) {
            case AddAndEditUserRoleModalDialogMode.Add:
                return UI_CONSTANTS.ADD;
            case AddAndEditUserRoleModalDialogMode.Edit:
                return UI_CONSTANTS.SAVE;
        }
        console.assert(
            false,
            'AddAndEditUserRoleModalDialogComponent#get labelForSubmitButton - this.mode has an unknown value'
        );
        return '';
    }

    public get deleteButtonTooltip(): string {
        if (this.canDelete === true) {
            return '';
        } else {
            return UI_CONSTANTS.TOOLTIPS.DELETE_DISABLED;
        }
    }
    public get shouldDisableDeleteButton(): boolean {
        return this.canDelete == false;
    }

    public get shouldShowDeleteButton(): boolean {
        return AddAndEditUserRoleModalDialogMode.Edit == this.mode;
    }

    public get hasSubmitted(): boolean {
        console.assert(this.formContainer != null, 'UserAddComponent#hasSubmitted - this.formContainer was null');

        return this.formContainer.submitted;
    }

    public shouldControlShowErrorStyle(control: NgControl): boolean {
        console.assert(control != null, 'UserAddComponent#shouldControlShowErrorStyle - control argument was null');

        if (true === control.pristine && this.hasSubmitted == false) {
            return false;
        }

        if (control === this.facilitySelect) {
            if (!control.disabled) {
                if (control.value === null || control.value == undefined) {
                    return true;
                } 
            }
        }

        return control.errors != null;
    }

    private handleFormErrors(): void {
        let areAllRequiredFieldsFilled: boolean = true;

        if (this.facilitySelect.errors && this.facilitySelect.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.roleSelect.errors && this.roleSelect.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
    }

    private populateResult(shouldDelete?: boolean): void {
        
        this.result = {
            selectedFacility: this.selectedFacility,
            selectedRole: this.selectedRole,
        };

        if (true === shouldDelete) {
            this.result.shouldDelete = true;
        }
    }
}
